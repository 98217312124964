import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;

  .left {
    background-image: url(${({ leftBackgroundImageURL }) => leftBackgroundImageURL});
    background-repeat: no-repeat;
    background-size: cover;
  }

  .right {
    background-color: #736356;
    padding: 60px 0;
    @media ${devices.xs} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media ${devices.sm} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media ${devices.md} {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 40px;
    }
  }
`;

export const WrapperText = styled.div`
  ${({ titleColor }) => titleColor ? `
    h2 {
      color: ${titleColor};
      font-weight: 700;
      font-size: 28px;
      line-height: 32px;
    }
  `: ""}

  ${({ contentColor }) => contentColor ? `
    margin-top: 10px;
    color: ${contentColor};
    font-weight: 700;
    font-size: 20px;

    ul {
      font-weight: normal;
    }
  `: ""}
`;