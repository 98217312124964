import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  background-color: ${({ backgroundColor }) => backgroundColor};

  .circleCard {
    position: absolute;
    top: -20px;
    z-index: 2;

    h3 {
      padding: 5px 20px;
      border-radius: 15px;
      border: 2px solid #FFF;
      outline: 2px solid #736356;
      background-color: ${({ circleCardColor }) => circleCardColor};
      color: #FFFFFF;
      font-weight: bold;
      text-align: center;
    }
  }

  .circles {
    display: flex;
    flex-wrap: wrap;
    gap: 3em; 
    margin-bottom: 20px;
  }
`;

export const WrapperCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 295px;
  height: 250px;
  padding: 10px;
  border: 4px dashed ${({ borderColor }) => borderColor};
  border-radius: 10px;
  background-color: #FFFFFF;

  @media ${devices.xs} {
    flex-direction: column;
  }

  @media ${devices.sm} {
    flex-direction: column;
  }

  @media ${devices.lg} {
    flex-direction: row;
  }

  @media ${devices.xl} {
    flex-direction: row;
  }

  .text {
    color: ${({ textColor }) => textColor};
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }
`;

export const WrapperText = styled.div`
  ${({ titleColor }) =>
    titleColor
      ? `
      h2 {
        font-size: 28px;
        line-height: 32px;
        font-weight: 800;
        color: ${titleColor};
        text-align: center;
        margin-bottom: 40px;
      }
  ` : ""}

  ${({ descriptionColor }) =>
    descriptionColor
      ? `
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: ${descriptionColor};
    text-align: center;
    position: relative;
    margin-top: 40px;
  ` : ""}
`;