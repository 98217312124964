import _ from "lodash";
import { useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Carousel from "react-spring-3d-carousel";

// Styles
import {
  Wrapper,
  WrapperCard,
  WrapperText,
  CardSubTitle,
  CardTitle,
} from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  const cardsText = t("COMP00018.cards", { returnObjects: true });

  const [actualSlide, setActualSlide] = useState(0);

  const slides = _.map(cardsText, (item, index) => {
    return {
      key: index,
      content: (
        <WrapperCard
          color="#FFF"
          className="d-flex justify-content-center align-items-center flex-column"
        >
          <div className="d-flex justify-content-center align-items-center flex-column">
            <CardTitle color="#000">
              {_.get(cardsText, `${index}.title`)}
            </CardTitle>
            <Col xs={10} xl={9}>
              <CardSubTitle color="#000">
                {_.get(cardsText, `${index}.subTitle`)}
              </CardSubTitle>
            </Col>
          </div>
        </WrapperCard>
      ),
    };
  });

  return (
    <Wrapper id="COMP00018">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Col xs={10} sm={11} className="d-flex flex-column justify-content-center">
          <WrapperText titleColor="#FFF" dangerouslySetInnerHTML={{ __html: t("COMP00018.title") }}>
          </WrapperText>
        </Col>
      </div>
      <Col className="d-flex flex-row justify-content-center g-0 w-100 h-100">
        <Col className="d-none d-md-flex justify-content-center align-items-center">
          <FaArrowLeft
            className="arrow"
            fill="#FFF"
            onClick={() => {
              setActualSlide(actualSlide - 1);
            }}
          />
        </Col>
        <Col xs={11} sm={10} md={8} lg={6} xl={4} className="col">
          <Carousel
            goToSlide={actualSlide}
            slides={slides}
            animationConfig={{ tension: 280, friction: 60 }}
          />
        </Col>
        <Col className="d-none d-md-flex justify-content-center align-items-center">
          <FaArrowRight
            className="arrow"
            fill="#FFF"
            onClick={() => {
              setActualSlide(actualSlide + 1);
            }}
          />
        </Col>
      </Col>
      <div className="d-md-none d-flex flex-row justify-content-between w-50 arrows-xs">
        <div className="d-flex justify-content-center align-items-center">
          <FaArrowLeft
            className="arrow"
            fill="#FFF"
            onClick={() => {
              setActualSlide(actualSlide - 1);
            }}
          />
        </div>
        <div className="d-md-none d-flex justify-content-center align-items-center">
          <FaArrowRight
            className="arrow"
            fill="#FFF"
            onClick={() => {
              setActualSlide(actualSlide + 1);
            }}
          />
        </div>
      </div>
      <Col xs={10} sm={11} className="d-flex flex-column justify-content-center">
        <WrapperText textColor="#FFF" dangerouslySetInnerHTML={{ __html: t("COMP00018.bottomText") }}></WrapperText>
      </Col>
    </Wrapper>
  );
};

export default Wireframe;