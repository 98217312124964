import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
  background-color: #D26630;

  .col {
    min-height: 380px;
  }

  .arrow {
    height: 2em;
    width: 2em;
    cursor: pointer;
  }
`;

export const WrapperCard = styled.div`
  width: 360px;
  border-radius: 10px;
  background-color: ${({ color }) => color};

  @media ${devices.xs} {
    width: 300px;
  }
`;

export const WrapperText = styled.div`
  ${({ titleColor }) =>
    titleColor
      ? `
    h2 {
      margin-bottom: 20px;
      color: ${titleColor};
      font-size: 28px
      line-height: 32px;
      font-weight: 800;
      text-align: center;
    }
  `
  : `
    margin-top: 20px;  
    color: #FFF;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  `}
`;

export const CardTitle = styled.div`
  padding-top: 30px;
  color: ${({ color }) => color};
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
`;

export const CardSubTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-style: italic;
  font-weight: 300;
  text-align: center;
  color: ${({ color }) => color};
  padding: 30px 0 60px 0;

  @media ${devices.xs} {
    font-size: 16px;
  }
`;