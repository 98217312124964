import styled from 'styled-components'
import devices from '../../../utils/helperGrids'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ backgroundColor }) => backgroundColor};

  .block-bottom {
    padding: 60px 0 80px;
  }
  
  .row-cards {
    @media ${devices.xs} {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5em;
    }

    @media ${devices.sm} {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5em;
    }

    @media ${devices.md} {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .row-button {
    margin-top: 35px;
  }
`;

export const WrapperCard = styled.div`
  width: 300px;
  margin: 0 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 16px ${({ cardShadowColor }) => cardShadowColor};

  .number {
    position: absolute;
    width: 300px;
    margin: -35px auto 0;
    padding: 8px;
    border-radius: 8px 8px 0 0;
    background-color: ${({ mainColor }) => mainColor};
    color ${({ numberColor }) => numberColor};
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    min-height: 430px;
    padding: 12px;
    border-radius: 10px;
    background-color: ${({ cardBackgroundColor }) => cardBackgroundColor};
  }

  .content-description {
    color: ${({ descriptionColor }) => descriptionColor};
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
`

export const WrapperText = styled.div`
  ${({ titleColor }) => titleColor ? `
    h2 {
      margin-top: 60px;
      color: ${titleColor};
      font-size: 28px;
      line-height: 32px;
      font-weight: 800;
      text-align: center;
    }
  ` : ''}
`