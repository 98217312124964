import _ from "lodash";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Components
import Button from "../../Button";

// Styles
import { Wrapper, WrapperCard, WrapperTitle, WrapperText } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  const cards = t("COMP00004.cards", { returnObjects: true });

  return (
    <Wrapper
      backgroundColor="#736356" 
      id="COMP00004"
    >
      <Row className="justify-content-center px-5 text-center g-0 w-100 h-100 row-title">
        <WrapperTitle titleColor="#FFFFFF">{t("COMP00004.title")}</WrapperTitle>
      </Row>
      <Row className="justify-content-center g-0 h-100 row-cards">
        {_.map(cards, (item, index) => (
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={4}
            className="d-flex justify-content-center"
            key={index}
          >
            <WrapperCard
              cardBackgroundColor="#FFF"
              descriptionColor="#D26630"
            >
              <div className="content">
                <div className="content-description">{_.get(item, "span")}</div>
              </div>
            </WrapperCard>
          </Col>
        ))}
      </Row>
      <Row className="justify-content-center g-0 w-100 h-100">
        <Col
          xs={10}
          sm={10}
          md={10}
          lg={8}
        >
          <WrapperText
            textColor="#FFFFFF"
            dangerouslySetInnerHTML={{ __html: t("COMP00004.bottomText") }}
          ></WrapperText>
        </Col>
      </Row>
      <Row className="justify-content-center g-0 w-100 h-100 row-button">
        <Col
          xs={10}
          sm={10}
          md={10}
          lg={6}
          className="d-flex justify-content-center"
        >
          <Button
            secondaryColor="#FFFFFF"
            primaryColor="#D26630"
            icon={false}
            buttonWidthVariable={true}
          >
            {t("COMP00004.button")}
          </Button>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Wireframe;