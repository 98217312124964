import i18n_translations from "../../components/PropagoCheckout/i18n/translations";

const i18n = {
  "pt-BR": {
    translations: {
      Page: {
        title: "Tudo Sobre o Instituto Olhar: Transformando Vidas com Amor e Empatia",
      },
      ButtonFloating: {
        button: "Doe Agora",
        buttonBottom: "Faça uma doação",
      },
      HEAD00002: {
        title: "INSTITUTO OLHAR",
        subTitle: `<p><strong>Restaurando Vidas e Reconstruindo Futuros</strong></p>
        <p>Nossa jornada é marcada pela dedicação e comprometimento em transformar não apenas indivíduos, mas também comunidades inteiras.</p>
        <p>O Instituto Olhar, em 2018, nasceu da convicção de que a violência doméstica é um problema que requer soluções profundas, abrangentes e sistêmicas. Em nossa cidade, Ribeirão Preto, São Paulo, percebemos a necessidade de uma intervenção que não se limitasse apenas a punições, mas que buscasse a reabilitação e a reparação dos envolvidos em casos de violência contra a mulher. Foi assim que o Instituto Olhar surgiu, como um raio de esperança em um cenário muitas vezes desolador.</p>`,
        button: "Saiba mais",
      },
      COMP00003: {
        title: "AMOR, EMPATIA, AUTOCONHECIMENTO.",
        subTitle:
          `<p>Nosso Instituto, corajosamente, oferece uma oportunidade de redenção a homens condenados pela Lei Maria da Penha. Acreditamos que, ao abordar as raízes do comportamento violento, podemos evitar que novos casos de violência ocorram e, ao mesmo tempo, proporcionar uma chance real de recuperação para aqueles que cometeram tais atos.</p>
          <p>Preparados para atuarmos como agente de transformação, somos cadastrados na Justiça Restaurativa de Ribeirão Preto, com uma forte parceria com a Vara de Violência Doméstica.</p>
          <p>Nossa presença e atuação estendem-se além das fronteiras da cidade, abrangendo as Comarcas de Ribeirão Preto, Sertãozinho e Taquaritinga. Nossos esforços não conhecem limites geográficos, pois acreditamos que cada vida que transformamos tem o potencial de causar um impacto positivo em toda a sociedade. Aqui, no Instituto Olhar, nossa abordagem é humanizada e centrada na pessoa. Compreendemos que a violência é um sintoma de problemas mais profundos e que a reabilitação requer uma jornada de autoconhecimento e autotransformação. Somos uma prova de que a esperança pode nascer até mesmo nos lugares mais sombrios. Acreditamos que não adianta só punir, o combate às causas da violência é essencial para enfrentar o problema.</p>
          <p><strong>A transformação pessoal é a chave para a construção de um mundo mais compassivo e harmonioso.</strong></p>`,
        button: "SUA DOAÇÃO É UMA OPORTUNIDADE DE MUDAR VIDAS",
      },
      COMP00024: {
        title: "<h2>ATIVIDADES DO INSTITUTO</h2>",
        topText: `<p>No Instituto Olhar, acreditamos que a transformação pessoal e a construção de relacionamentos saudáveis são possíveis através do desenvolvimento socioemocional, do autoconhecimento e do cultivo de práticas de bem-estar. Nossos cursos e atividades são projetados para capacitar os participantes a construir um futuro mais harmonioso para si mesmos e para suas comunidades. Nossas principais atividades são:</p>`,
        cards: [
          {
            title: `<h3>Cursos de Desenvolvimento Socioemocional</h3>
            <p>No Instituto Olhar, acreditamos que o desenvolvimento socioemocional desempenha um papel fundamental na transformação pessoal. Oferecemos cursos cuidadosamente elaborados para promover a conscientização emocional e o fortalecimento das habilidades interpessoais.</p>`,
            alt: "Lorem ipsum"
          },
          {
            title: `<h3>Autoconhecimento</h3>
            <p>A jornada de transformação começa com o autoconhecimento. Incentivamos os participantes a explorarem seu mundo interior, compreenderem suas motivações e reconhecerem padrões de comportamento.</p>`,
            alt: "Dolor sit"
          },
          {
            title: `<h3>Yoga</h3>
            <p>O Yoga é uma ferramenta poderosa para o equilíbrio físico e mental. Oferecemos aulas de Yoga como parte de nosso compromisso com o bem-estar integral. O Yoga ajuda os participantes a melhorarem a flexibilidade, reduzir o estresse e encontrar paz interior. É uma prática que complementa nossa abordagem holística para a transformação.</p>`,
            alt: "Amet, consequitetur"
          },
          {
            title: `<h3>Mindfulness</h3>
            <p>A atenção plena, ou mindfulness, é uma prática que ensinamos para ajudar os participantes a viverem o presente com consciência. Ensinamos técnicas de mindfulness que podem ser aplicadas no dia a dia, permitindo que as pessoas enfrentem desafios com calma, clareza e empatia.</p>`,
            alt: "Adipiscin elit"
          },
          {
            title: `<h3>Comunicação Não Violenta</h3>
            <p>A comunicação é a chave para relacionamentos saudáveis. Oferecemos treinamento com uma abordagem que promove a compreensão mútua e a resolução pacífica de conflitos. Ensinamos habilidades de comunicação que ajudam os participantes a expressarem suas necessidades e sentimentos de maneira respeitosa e empática.</p>`,
            alt: "sed do"
          },
          {
            title: `<h3>Grupos Reflexivos</h3>
            <p>Nossos grupos reflexivos são espaços seguros onde os participantes podem compartilhar suas experiências, aprender com os outros e refletir sobre seu progresso. Estes grupos proporcionam uma oportunidade valiosa para a construção de apoio mútuo e para a aplicação prática das habilidades e conhecimentos adquiridos em nossos cursos.</p>`,
            alt: "sed do"
          },
        ],
        bottomText: `<p>Acreditamos no potencial de cada indivíduo para se tornar a melhor versão de si mesmo.</p>
        <p><strong class="special-text">DESENVOLVIMENTO | PAZ | COMPAIXÃO</strong></p>`,
        button: "SUA GENEROSIDADE É A LUZ QUE GUIA NOSSA JORNADA"
      },
      COMP00005: {
        title: "DIRETRIZES DO INSTITUTO",
        cards: [
          {
            title: 'MISSÃO',
            text: `<p>Nossa missão é ser um canal de transformação e desenvolvimento humano.</p> 
            <p>Buscamos auxiliar crianças, jovens e adultos, independentemente de sua origem social ou contexto, a se perceberem como seres integrais e integrados.</p> 
            <p>Em nossa jornada, dedicamo-nos a promover a conscientização emocional, o autoconhecimento e a construção de relacionamentos saudáveis. Acreditamos que, ao ajudar as pessoas a se tornarem versões melhores de si mesmas, contribuímos para a construção de uma sociedade mais harmoniosa e compassiva.</p>`,
          },
          {
            title: 'VISÃO',
            text: `<p>Nossa visão é ser uma referência no Poder Judiciário, nas escolas e nas empresas como um agente de transformação de comportamentos.</p>
            <p>Através de nossos programas e cursos, aspiramos a criar um impacto duradouro na vida das pessoas e nas comunidades com a promoção do desenvolvimento socioemocional, do autoconhecimento e da cultura de paz.</p>
            <p>Enxergamos um futuro em que nossos esforços se traduzam em uma sociedade mais justa, empática e harmoniosa.</p>`,
          },
        ],
      },
      COMP00018: {
        title: "<h2>VALORES</h2>",
        cards: [
          {
            title: "Ética",
            subTitle: "A ética é o alicerce de nosso trabalho. Mantemos padrões elevados de conduta moral e integridade em todas as nossas interações. Agimos com honestidade, transparência e respeito em relação às pessoas que servimos e com as quais colaboramos.",
          },
          {
            title: "Não-violência",
            subTitle: "A não-violência é um valor central em nossa abordagem. Acreditamos que a transformação verdadeira ocorre através do diálogo, da compaixão e da resolução pacífica de conflitos. Promovemos a não-violência em todas as suas formas, desde a violência física até a violência verbal e psicológica.",
          },
          {
            title: "Amor Incondicional",
            subTitle: "Valorizamos o amor incondicional como uma força motriz para a mudança positiva. Nutrimos o amor incondicional em nossas relações, demonstrando compaixão, empatia e apoio aos que atendemos."
          },
          {
            title: "Qualidade",
            subTitle: "Buscamos a excelência em tudo o que fazemos. Comprometemo-nos com a qualidade em nossos programas, cursos e serviços, garantindo que eles atendam às necessidades e expectativas de nossos participantes. A qualidade é um reflexo de nosso compromisso com a transformação positiva."
          },
          {
            title: "Aprimoramento Constante",
            subTitle: "Reconhecemos que a aprendizagem e o crescimento contínuo são essenciais para alcançar nossa missão e visão. Estamos sempre em busca de aprimorar nossos métodos, técnicas e abordagens. Valorizamos a adaptabilidade e a inovação como meios de nos mantermos relevantes e eficazes em nosso trabalho."
          },
        ],
        bottomText: "<p><strong>Nossa missão é simples, mas poderosa: inspirar vidas, cultivar a empatia e promover a paz.</strong></p>"
      },
      COMP00004: {
        title: "NOSSOS NÚMEROS",
        cards: [
          { span: "5 ANOS DE INSTITUTO" },
          { span: "DIMINUIÇÃO DA TAXA DE VIOLÊNCIA DE 65% A 2%" },
          { span: "95% DE APROVAÇÃO DOS" },
          { span: "MAIS DE 200 PARTICIPANTES" }
        ],
        bottomText: `<p><strong>Cada história de sucesso em nosso instituto é uma prova viva de que a transformação é possível, não importa quais desafios você tenha enfrentado no passado.</strong></p>`,
        button: "JUNTE-SE A NÓS NA CONSTRUÇÃO DE UM MUNDO MAIS COMPASSIVO",
      },
      COMP00017: {
        title: "<h2>LINHA DO TEMPO DO INSTITUTO</h2>",
        subTitle:
          "Aqui no Instituto Olhar, valorizamos cada pessoa que cruza nosso caminho, oferecendo amor, apoio e oportunidades para o crescimento.",
        cards: [
          {
            title: "2018",
            text: "Início de nossa jornada e parceria com a equipe do Judiciário.",
          },
          {
            title: "2019",
            text: "Expandimos nosso impacto ao iniciar o trabalho com os reeducandos da Lei Maria da Penha, focando na reabilitação e na construção de um futuro livre de violência.",
          },
          {
            title: "2020",
            text: "Adotamos uma abordagem inovadora, lançando as primeiras turmas online.",
          },
          {
            title: "2022",
            text: "Estendemos nossas raízes até Taquaritinga, ampliando nosso alcance geográfico e levando nossa missão a uma nova comunidade.",
          },
          {
            title: "2023",
            text: `<p>Estabelecemos uma parceria com uma escola para um projeto piloto, levando nossos princípios de transformação para o ambiente educacional, moldando futuros mais promissores para os alunos.</p>`,
          },
          {
            title: "2023",
            text: "Estamos formando produtos e serviços para empresas que buscam promover o desenvolvimento socioemocional, o autoconhecimento e a cultura de paz entre seus funcionários.",
          },
          {
            title: "2023",
            text: "<p>Além disso estabelecemos parcerias com organizações voltadas para dependentes químicos, com Sertãozinho e com presídios.</p>"
          }
        ],
      },
      COMP00023: {
        title: '<h2>METAS E OBJETIVOS</h2>',
        line1:
          `<p>Expandir Nossa Presença nas Redes Sociais</p>
        <ul>
          <li>Queremos alcançar 1.000 seguidores no Instagram para compartilhar nossa mensagem de transformação com uma comunidade maior.</li>
        </ul>`,
        line2:
          `<p>Levar Nossos Cursos para Empresas e Escolas</p>
        <ul>
          <li>Buscamos a prospecção de parcerias com empresas e escolas para oferecer nossos programas de desenvolvimento socioemocional e cultura de paz a públicos mais amplos.</li>
        </ul>`,
      },
      COMP_ALLYA: {
        title: `Faça parte do `,
        description: `
          <p>Sua doação é muito importante para nós e faz toda a diferença. O processo de <strong>doação</strong> é simples, seguro e fácil. O dinheiro doado é revertido para as nossas ações sociais e filantrópicas. Você pode doar uma única vez ou todos os meses, de forma recorrente. Quer sua doação seja grande ou pequena, saiba que ela é uma prova do seu compromisso com um mundo melhor para todos. Junte-se a nós hoje na criação de um futuro onde o poder duradouro do amor prevaleça. </p>
          <p><strong>Doe todos os meses</strong> para <strong>Instituto Olhar</strong> e participe do nosso <strong>Clube do Bem</strong>, uma parceria altruísta e  filantrópica entre <strong>Propago e Allya.</strong></p>
          <p>No Clube do Bem da Propago  você irá economizar dinheiro, tornar seu dia a dia mais prático e o melhor de tudo, sua economia pode virar uma doação!</p>
          <p>Você contará com uma série de <strong>descontos, vantagens e benefícios</strong> em mais de <strong>30 mil estabelecimentos</strong> como: Netshoes, Magazine Luiza, Cinemark, Droga Raia, EspaçoLaser e muito mais! </p>
        `,
        list: [
          {
            area: 'Beleza e bem-estar! -',
            discount: 'Até 80%'
          },
          {
            area: 'Lazer -',
            discount: 'Até 50% OFF'
          },
          {
            area: 'Restaurantes -',
            discount: 'Até 35% OFF'
          },
          {
            area: 'Cursos e Faculdades -',
            discount: 'Até 80%'
          },
        ],
        bottomDescription: `Viu como sua economia pode gerar o bem para todo o ecossistema?`,
        text: `A <strong style="color: #F73D6A;">Allya</strong> tem como objetivo ajudar pessoas do bem  a manterem um padrão de vida mais econômico de forma inteligente, levando consigo a filantropia!`,
      },
      CHEC00001: {
        title: "Sua doação irá <strong>transformar a vida</strong> de alguém.",
        legend: "Apoio",
      },
      FAQS00001: {
        title: "<strong>Perguntas Frequentes</strong> sobre a doação:",
        cards: [
          {
            title: "Devo declarar as doações no imposto de renda?",
            subTitle:
              "Em conformidade com as regras da Receita Federal do Brasil, o doador precisa informar todas as doações realizadas em suas declarações financeiras. Lembrando que, segundo o Regulamento do Imposto de Renda – RIR (Decreto nº 9.580, de 22/11/ 2018), não há dedução no Imposto de Renda de Pessoa Física (IRPF) para doações a projetos como os realizados pelo Instituto Olhar.<br /><br />O valor doado deve ser declarado na ficha DOAÇÕES EFETUADAS sob o código 99 – Outras doações, uma vez que se trata de doação não incentivada (não permite dedução do IR a pagar do doador).",
          },
          {
            title: "Quais são as formas de pagamento para doações no site?",
            subTitle:
              "Você pode fazer sua doação, seja ela pontual ou recorrente, via boleto bancário, cartão de crédito (todas as bandeiras) ou via pix.",
          },
          {
            title: "Por que o Instituto Olhar emitiu uma cobrança em meu nome?",
            subTitle:
              "Para que os pagamentos sejam mais transparentes e seguros, a Federação Brasileira de Bancos (Febraban) instituiu que todos os boletos sejam registrados em uma plataforma de cobrança desenvolvida pela própria Febraban, que permite aos bancos informar ao cliente toda vez que um novo boleto for emitido em seu nome. Clientes cadastrados no serviço Débito Direto Autorizado (DDA) podem ser informados pelos bancos sobre a cobrança no momento do registro do boleto, pelos canais digitais ou com o envio de torpedos.",
          },
          {
            title: "Se eu não pagar o boleto, meu nome pode ser negativado?",
            subTitle:
              "Não. O pagamento dos boletos enviados pelo Instituto Olhar é totalmente facultativo.",
          },
          {
            title:
              "Meu boleto veio com um valor que eu não quero doar, o que devo fazer?",
            subTitle:
              "Você pode escolher algum dos valores sugeridos para doação, ou definir uma outra quantia que seja de sua preferência, clicando no botão “outro valor”. Se o valor do boleto emitido não for igual ao que você pretende doar, pode emitir outro.",
          },
          {
            title: "O que eu faço se meu boleto vier sem código de barras?",
            subTitle:
              "Se você recebeu um boleto sem código de barras, isso indica que você está cadastrado no Débito Direto Autorizado (DDA). Por determinação das novas regras da Febraban, clientes cadastrados no serviço DDA não recebem mais boletos com códigos de barras. Para pagar seu boleto basta autorizar o débito junto ao seu banco, pela internet, pelo telefone ou nos caixas eletrônicos.",
          },
          {
            title: "O que é DDA?",
            subTitle:
              "O Débito Direto Autorizado (DDA) é um sistema criado pela Federação Brasileira de Bancos (Febraban) que substitui a emissão de boletos de cobrança impressos pela cobrança eletrônica dos pagamentos. Para ler o comunicado no site da Febraban, acesse: <a href='https://portal.febraban.org.br/pagina/3051/1088/pt-br/dda' target='_blank'>https://portal.febraban.org.br/pagina/3051/1088/pt-br/dda</a>",
          },
          {
            title: "Como posso me cadastrar no DDA?",
            subTitle:
              "Isso é um serviço oferecido pelos bancos. Para se cadastrar no Débito Direto Autorizado (DDA), basta entrar em contato com o seu banco, por telefone, pela internet ou nos caixas eletrônicos. Mesmo após o cadastro, você continua recebendo o boleto impresso; porém, sem o código de barras.",
          },
          {
            title: "Posso me descadastrar do DDA?",
            subTitle: "Para se descadastrar, entre em contato com o seu banco.",
          },
          {
            title:
              "Quero pagar meu boleto, mas a data de vencimento passou. Preciso emitir segunda via?",
            subTitle:
              "Não. Agora os boletos vencidos podem ser pagos em qualquer banco, pela internet, nos caixas eletrônicos ou em casas lotéricas.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Visite-nos!`,
        iframeTitle: "Localização do escritório de Instituto Olhar em Ribeirão"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2023 - Instituto Olhar ® - Todos os direitos podem ser compartilhados com você.",
        copyrightSpan: "Pergunte-nos como.",
      },
      SectionCookies: {
        title: "O Instituto Olhar se preocupa com você e com a sua privacidade",
        subTitle:
          "O nosso site usa cookies e outras tecnologias para personalizar a sua experiência e compreender como você e os outros visitantes usam o nosso site. Ao navegar pelo site, coletaremos tais informações para utilizá-las com estas finalidades. Caso não aceite, usaremos os cookies apenas necessários para o correto funcionamento do site",
        button: "Aceito",
      },
      Checkout: i18n_translations["pt-BR"].translations,
    },
  },
  "en-US": {
    translations: {
      Page: {
        title: "All about Instituto Olhar: Transforming Lives with Love and Empathy",
      },
      ButtonFloating: {
        button: "Donate Now",
        buttonBottom: "Make a donation",
      },
      HEAD00002: {
        title: "INSTITUTO OLHAR",
        subTitle: `<p><strong>Restoring Lives and Rebuilding Futures</strong></p>
        <p>Our journey is marked by dedication and commitment to transform not only individuals, but also entire communities.</p>
        <p>Instituto Olhar, in 2018, was born from the conviction that domestic violence is a problem that requires deep, comprehensive and systemic solutions. In our city, Ribeirão Preto, São Paulo, we perceived the need for an intervention that was not limited only to punishments, but sought rehabilitation and reparation of those involved in cases of violence against women. That's how Instituto Olhar emerged, as a ray of hope in an often desolate scenario.</p>`,
        button: "Learn more",
      },
      COMP00003: {
        title: "LOVE, EMPATHY, SELF-KNOWLEDGE.",
        subTitle:
          `<p>Our Institute, courageously, offers an opportunity for redemption to men convicted by the Maria da Penha Law. We believe that by addressing the roots of violent behavior, we can prevent new cases of violence from occurring and at the same time provide a real chance of recovery for those who have committed such acts.</p>
          <p>Prepared to act as an agent of transformation, we are registered with Restorative Justice of Ribeirão Preto, with a strong partnership with the Domestic Violence Court.</p>
          <p>Our presence and performance extend beyond the city's borders, covering the Districts of Ribeirão Preto, Sertãozinho and Taquaritinga. Our efforts know no geographical limits, as we believe that each life we transform has the potential to cause a positive impact on society as a whole. Here at Instituto Olhar, our approach is humanized and person-centered. We understand that violence is a symptom of deeper problems and that rehabilitation requires a journey of self-knowledge and self-transformation. We are proof that hope can be born even in the darkest places. We believe that it is not enough to just punish, combating the causes of violence is essential to face the problem.</p>
          <p><strong>Personal transformation is the key to building a more compassionate and harmonious world.</strong></p>`,
        button: "YOUR DONATION IS AN OPPORTUNITY TO CHANGE LIVES",
      },
      COMP00024: {
        title: "<h2>ACTIVITIES OF INSTITUTO OLHAR</h2>",
        topText: `<p>At Instituto Olhar, we believe that personal transformation and the construction of healthy relationships are possible through socio-emotional development, self-knowledge, and the cultivation of well-being practices. Our courses and activities are designed to empower participants to build a more harmonious future for themselves and their communities. Our main activities are:</p>`,
        cards: [
          {
            title: `<h3>Socio-Emotional Development Courses</h3>
            <p>At Instituto Olhar, we believe that socio-emotional development plays a fundamental role in personal transformation. We offer carefully crafted courses to promote emotional awareness and the strengthening of interpersonal skills.</p>`,
            alt: "Lorem ipsum"
          },
          {
            title: `<h3>Self-Knowledge</h3>
            <p>The journey of transformation begins with self-knowledge. We encourage participants to explore their inner world, understand their motivations, and recognize patterns of behavior.</p>`,
            alt: "Dolor sit"
          },
          {
            title: `<h3>Yoga</h3>
            <p>Yoga is a powerful tool for physical and mental balance. We offer Yoga classes as part of our commitment to integral well-being. Yoga helps participants improve flexibility, reduce stress, and find inner peace. It is a practice that complements our holistic approach to transformation.</p>`,
            alt: "Amet, consequitetur"
          },
          {
            title: `<h3>Mindfulness</h3>
            <p>Mindfulness, or full attention, is a practice we teach to help participants live the present with awareness. We teach mindfulness techniques that can be applied in everyday life, allowing people to face challenges with calmness, clarity, and empathy.</p>`,
            alt: "Adipiscin elit"
          },
          {
            title: `<h3>Nonviolent Communication</h3>
            <p>Communication is key to healthy relationships. We offer training with an approach that promotes mutual understanding and peaceful conflict resolution. We teach communication skills that help participants express their needs and feelings in a respectful and empathetic way.</p>`,
            alt: "sed do"
          },
          {
            title: `<h3>Reflective Group</h3>
            <p>Our reflective groups are safe spaces where participants can share their experiences, learn from others, and reflect on their progress. These groups provide a valuable opportunity for mutual support building and practical application of the skills and knowledge acquired in our courses.</p>`,
            alt: "sed do"
          },
        ],
        bottomText: `<p>We believe in the potential of each individual to become the best version of themselves.</p>
        <p><strong class="special-text">DEVELOPMENT | PEACE | COMPASSION</strong></p>`,
        button: "YOUR GENEROSITY IS THE LIGHT THAT GUIDES OUR JOURNEY"
      },
      COMP00005: {
        title: "INSTITUTO OLHAR GUIDELINES",
        cards: [
          {
            title: 'MISSION',
            text: `<p>Our mission is to be a channel of transformation and human development.</p> 
            <p>We seek to assist children, young people and adults, regardless of their social origin or context, to perceive themselves as integral and integrated beings.</p> 
            <p>In our journey, we dedicate ourselves to promoting emotional awareness, self-knowledge and the construction of healthy relationships. We believe that by helping people become better versions of themselves, we contribute to the construction of a more harmonious and compassionate society.</p>`,
          },
          {
            title: 'VISION',
            text: `<p>Our vision is to be a reference in the Judiciary, in schools and in companies as an agent of behavior transformation.</p>
            <p>Through our programs and courses, we aspire to create a lasting impact on people's lives and communities with the promotion of socio-emotional development, self-knowledge and peace culture.</p>
            <p>We see a future in which our efforts translate into a more just, empathetic and harmonious society.</p>`,
          },
        ],
      },
      COMP00018: {
        title: "<h2>VALUES</h2>",
        cards: [
          {
            title: "Ethics",
            subTitle: "Ethics is the foundation of our work. We maintain high standards of moral conduct and integrity in all our interactions. We act with honesty, transparency and respect towards the people we serve and with whom we collaborate.",
          },
          {
            title: "Non-violence",
            subTitle: "Non-violence is a central value in our approach. We believe that true transformation occurs through dialogue, compassion and peaceful conflict resolution. We promote non-violence in all its forms, from physical violence to verbal and psychological violence.",
          },
          {
            title: "Unconditional Love",
            subTitle: "We value unconditional love as a driving force for positive change. We nurture unconditional love in our relationships, demonstrating compassion, empathy and support to those we serve."
          },
          {
            title: "Quality",
            subTitle: "We strive for excellence in everything we do. We are committed to quality in our programs, courses and services, ensuring that they meet the needs and expectations of our participants. Quality is a reflection of our commitment to positive transformation."
          },
          {
            title: "Constant Improvement",
            subTitle: "We recognize that learning and continuous growth are essential to achieving our mission and vision. We are always looking to improve our methods, techniques and approaches. We value adaptability and innovation as ways to stay relevant and effective in our work."
          },
        ],
        bottomText: "<p><strong>Our mission is simple but powerful: inspire lives, cultivate empathy and promote peace.</strong></p>"
      },
      COMP00004: {
        title: "OUR NUMBERS",
        cards: [
          { span: "5 YEARS OF INSTITUTO OLHAR" },
          { span: "REDUCTION OF VIOLENCE RATE FROM 65% TO 2%" },
          { span: "95% APPROVAL OF" },
          { span: "MORE THAN 200 PARTICIPANTS" }
        ],
        bottomText: `<p><strong>Each success story at our institute is living proof that transformation is possible, no matter what challenges you have faced in the past.</strong></p>`,
        button: "JOIN US IN BUILDING A MORE COMPASSIONATE WORLD",
      },
      COMP00017: {
        title: "<h2>INSTITUTO OLHAR TIMELINE</h2>",
        subTitle:
          "Here at Instituto Olhar, we value each person who crosses our path, offering love, support and opportunities for growth.",
        cards: [
          {
            title: "2018",
            text: "Beginning of our journey and partnership with the Judiciary team.",
          },
          {
            title: "2019",
            text: "We expanded our impact by starting work with the reeducandos of the Maria da Penha Law, focusing on rehabilitation and building a future free from violence.",
          },
          {
            title: "2020",
            text: "We adopted an innovative approach, launching the first online classes.",
          },
          {
            title: "2022",
            text: "We extended our roots to Taquaritinga, expanding our geographical reach and bringing our mission to a new community.",
          },
          {
            title: "2023",
            text: `<p>We established a partnership with a school for a pilot project, bringing our principles of transformation to the educational environment, shaping more promising futures for students.</p>`,
          },
          {
            title: "2023",
            text: "We are forming products and services for companies that seek to promote socio-emotional development, self-knowledge and peace culture among their employees.",
          },
          {
            title: "2023",
            text: "<p>In addition we established partnerships with organizations focused on drug addicts, with Sertãozinho and with prisons.</p>"
          }
        ],
      },
      COMP00023: {
        title: '<h2>GOALS AND OBJECTIVES</h2>',
        line1:
          `<p>Expand Our Presence on Social Networks</p>
        <ul>
          <li>We want to reach 1,000 followers on Instagram to share our message of transformation with a larger community.</li>
        </ul>`,
        line2:
          `<p>Take Our Courses to Companies and Schools</p>
        <ul>
          <li>We are looking for the prospecting of partnerships with companies and schools to offer our programs of socio-emotional development and peace culture to wider audiences.</li>
        </ul>`,
      },
      COMP_ALLYA: {
        title: 'Join the ',
        description: `
          <p>Your donation is very important to us and makes all the difference. The process of <strong>donation</strong> is simple, secure, and easy. The donated money is reverted to our social and philanthropic actions. You can donate once or every month, recurrently. Whether your donation is big or small, know that it is proof of your commitment to a better world for everyone. Join us today in creating a future where the lasting power of love prevails.</p>
          <p><strong>Donate every month</strong> to <strong>Instituto Olhar</strong> and participate in our <strong>Clube do Bem</strong> (Club of Good), an altruistic and philanthropic partnership between <strong>Propago and Allya.</strong></p>
          <p>In Propago's Clube do Bem you will save money, make your day-to-day more practical and best of all, your savings can turn into a donation!</p>
          <p>You will have a series of <strong>discounts, advantages, and benefits</strong> in more than <strong>30 thousand establishments</strong> such as: Netshoes, Magazine Luiza, Cinemark, Droga Raia, EspaçoLaser and much more!</p>
        `,
        list: [
          {
            area: 'Beauty and well-being! -',
            discount: 'Up to 80% OFF'
          },
          {
            area: 'Leisure - ',
            discount: 'Up to 50% OFF'
          },
          {
            area: 'Restaurants -',
            discount: 'Up to 35% OFF'
          },
          {
            area: 'Courses and Undergraduations -',
            discount: 'Up to 80% OFF'
          },
        ],
        bottomDescription: `<p>See how your economy can do good for the whole ecosystem?</p>`,
        text: `<strong style="color: #F73D6A;">Allya</strong> aims to aims to help good people maintain a more economical standard of living in an intelligent way, bringing philanthropy with it!`,
      },
      CHEC00001: {
        title: "Your donation will <strong>change someone's life</strong>",
        legend: "By",
      },
      FAQS00001: {
        title: "<strong>Frequently Asked Questions</strong> about the donation:",
        cards: [
          {
            title: "Does Instituto Olhar accept donations in kind",
            subTitle:
              "Unfortunately, we cannot accept donations in kind.",
          },
          {
            title: "How can i be sure my online donation has been accepted?",
            subTitle:
              "If you entered a valid email address, you will receive a message confirming your donation. If you don't receive an email within about 15 minutes, please contact us.",
          },
          {
            title: "What are the payment methods for donations on the website?",
            subTitle: "You can make your donation, whether punctual or recurring by credit card."
          },
          {
            title: "Why is monthly giving the best option?",
            subTitle:
              "Monthly giving is the best option for both Instituto Olhar and our supporters. It allows us to have a dependable base of support and provides predictable funding. For our supporters it is the easiest way to donate, since your credit card deducts your giving automatically.",
          },
          {
            title: "Is the donation page secure?",
            subTitle: "We are proud to meet strict data security standards in order to process your donation online.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Visit us!`,
        iframeTitle: "Localization of Instituto Olhar's office in Ribeirão Preto - SP (Brazil)"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2023 - Instituto Olhar ® - All rights can be shared with you.",
        copyrightSpan: "Ask us how.",
      },
      SectionCookies: {
        title: "Instituto Olhar worries about your privacy.",
        subTitle:
          "This website uses cookies and other technologies to improve your experience and to analyze the traffic in our website. Once you are browsing we will colect such information for this analysis. If you don't accept it, we will use only cookies that are needed to run the website.",
        button: "I Accept",
      },
      Checkout: i18n_translations.en.translations,
    }
  },
  es: {
    translations: {
      Page: {
        title: "Todo sobre el Instituto Olhar: Transformando vidas con amor y empatía",
      },
      ButtonFloating: {
        button: "Dona ahora",
        buttonBottom: "Haz una donácion",
      },
      HEAD00002: {
        title: "INSTITUTO OLHAR",
        subTitle: `<p><strong>Restaurando Vidas y Reconstruyendo Futuros</strong></p>
        <p>Nuestro viaje está marcado por la dedicación y el compromiso para transformar no solo a individuos, sino también a comunidades enteras.</p>
        <p>El Instituto Olhar, en 2018, nació de la convicción de que la violencia doméstica es un problema que requiere soluciones profundas, integrales y sistémicas. En nuestra ciudad, Ribeirão Preto, São Paulo, percibimos la necesidad de una intervención que no se limitara solo a castigos, sino que buscara la rehabilitación y reparación de los involucrados en casos de violencia contra la mujer. Así es como surgió el Instituto Olhar, como un rayo de esperanza en un escenario a menudo desolador.</p>`,
        button: "Saber más",
      },
      COMP00003: {
        title: "AMOR, EMPATÍA, AUTOCONOCIMIENTO.",
        subTitle:
          `<p>Nuestro Instituto, valientemente, ofrece una oportunidad de redención a los hombres condenados por la Ley Maria da Penha. Creemos que al abordar las raíces del comportamiento violento, podemos evitar que se produzcan nuevos casos de violencia y al mismo tiempo proporcionar una oportunidad real de recuperación para aquellos que han cometido tales actos.</p>
          <p>Preparados para actuar como agentes de transformación, estamos registrados en la Justicia Restaurativa de Ribeirão Preto, con una fuerte asociación con el Tribunal de Violencia Doméstica.</p>
          <p>Nuestra presencia y actuación se extienden más allá de las fronteras de la ciudad, abarcando las Comarcas de Ribeirão Preto, Sertãozinho y Taquaritinga. Nuestros esfuerzos no conocen límites geográficos, ya que creemos que cada vida que transformamos tiene el potencial de causar un impacto positivo en toda la sociedad. Aquí, en el Instituto Olhar, nuestro enfoque es humanizado y centrado en la persona. Comprendemos que la violencia es un síntoma de problemas más profundos y que la rehabilitación requiere un viaje de autoconocimiento y autotransformación. Somos una prueba de que la esperanza puede nacer incluso en los lugares más oscuros. Creemos que no basta con castigar, combatir las causas de la violencia es esencial para enfrentar el problema.</p>
          <p><strong>La transformación personal es la clave para construir un mundo más compasivo y armonioso.</strong></p>`,
        button: "TU DONACIÓN ES UNA OPORTUNIDAD PARA CAMBIAR VIDAS",
      },
      COMP00024: {
        title: "<h2>ACTIVIDADES DEL INSTITUTO OLHAR</h2>",
        topText: `<p>En el Instituto Olhar, creemos que la transformación personal y la construcción de relaciones saludables son posibles a través del desarrollo socioemocional, el autoconocimiento y el cultivo de prácticas de bienestar. Nuestros cursos y actividades están diseñados para capacitar a los participantes para construir un futuro más armonioso para ellos mismos y para sus comunidades. Nuestras principales actividades son:</p>`,
        cards: [
          {
            title: `<h3>Cursos de Desarrollo Socioemocional</h3>
            <p>En el Instituto Olhar, creemos que el desarrollo socioemocional juega un papel fundamental en la transformación personal. Ofrecemos cursos cuidadosamente elaborados para promover la conciencia emocional y el fortalecimiento de las habilidades interpersonales.</p>`,
            alt: "Lorem ipsum"
          },
          {
            title: `<h3>Autoconocimiento</h3>
            <p>El viaje de transformación comienza con el autoconocimiento. Animamos a los participantes a explorar su mundo interior, comprender sus motivaciones y reconocer patrones de comportamiento.</p>`,
            alt: "Dolor sit"
          },
          {
            title: `<h3>Yoga</h3>
            <p>El Yoga es una herramienta poderosa para el equilibrio físico y mental. Ofrecemos clases de Yoga como parte de nuestro compromiso con el bienestar integral. El Yoga ayuda a los participantes a mejorar la flexibilidad, reducir el estrés y encontrar paz interior. Es una práctica que complementa nuestro enfoque holístico para la transformación.</p>`,
            alt: "Amet, consequitetur"
          },
          {
            title: `<h3>Mindfulness</h3>
            <p>La atención plena, o mindfulness, es una práctica que enseñamos para ayudar a los participantes a vivir el presente con conciencia. Enseñamos técnicas de mindfulness que pueden ser aplicadas en la vida diaria, permitiendo que las personas enfrenten desafíos con calma, claridad y empatía.</p>`,
            alt: "Adipiscin elit"
          },
          {
            title: `<h3>Comunicación No Violenta</h3>
            <p>La comunicación es la clave para las relaciones saludables. Ofrecemos capacitación con un enfoque que promueve la comprensión mutua y la resolución pacífica de conflictos. Enseñamos habilidades de comunicación que ayudan a los participantes a expresar sus necesidades y sentimientos de manera respetuosa y empática.</p>`,
            alt: "sed do"
          },
          {
            title: `<h3>Grupo Reflexivos</h3>
            <p>Nuestros grupos reflexivos son espacios seguros donde los participantes pueden compartir sus experiencias, aprender de los demás y reflexionar sobre su progreso. Estos grupos proporcionan una oportunidad valiosa para la construcción de apoyo mutuo y para la aplicación práctica de las habilidades y conocimientos adquiridos en nuestros cursos.</p>`,
            alt: "sed do"
          },
        ],
        bottomText: `<p>Creamos en el potencial de cada individuo para convertirse en la mejor versión de sí mismo.</p>
        <p><strong class="special-text">DESARROLLO | PAZ | COMPASIÓN</strong></p>`,
        button: "TU GENEROSIDAD ES LA LUZ QUE GUIA NUESTRO VIAJE"
      },
      COMP00005: {
        title: "DIRECTRICES DEL INSTITUTO OLHAR",
        cards: [
          {
            title: 'MISIÓN',
            text: `<p>Nuestra misión es ser un canal de transformación y desarrollo humano.</p> 
            <p>Buscamos ayudar a niños, jóvenes y adultos, independientemente de su origen social o contexto, a percibirse como seres integrales e integrados.</p> 
            <p>En nuestro viaje, nos dedicamos a promover la conciencia emocional, el autoconocimiento y la construcción de relaciones saludables. Creemos que al ayudar a las personas a convertirse en las mejores versiones de sí mismas, contribuimos a la construcción de una sociedad más armoniosa y compasiva.</p>`,
          },
          {
            title: 'VISIÓN',
            text: `<p>Nuestra visión es ser una referencia en el Poder Judicial, en las escuelas y en las empresas como un agente de transformación de comportamientos.</p>
            <p>A través de nuestros programas y cursos, aspiramos a crear un impacto duradero en la vida de las personas y en las comunidades con la promoción del desarrollo socioemocional, del autoconocimiento y de la cultura de paz.</p>
            <p>Vemos un futuro en el que nuestros esfuerzos se traduzcan en una sociedad más justa, empática y armoniosa.</p>`,
          },
        ],
      },
      COMP00018: {
        title: "<h2>VALORES</h2>",
        cards: [
          {
            title: "Ética",
            subTitle: "La ética es el fundamento de nuestro trabajo. Mantenemos altos estándares de conducta moral e integridad en todas nuestras interacciones. Actuamos con honestidad, transparencia y respeto hacia las personas a las que servimos y con las que colaboramos.",
          },
          {
            title: "No violencia",
            subTitle: "La no violencia es un valor central en nuestro enfoque. Creemos que la verdadera transformación ocurre a través del diálogo, la compasión y la resolución pacífica de conflictos. Promovemos la no violencia en todas sus formas, desde la violencia física hasta la violencia verbal y psicológica.",
          },
          {
            title: "Amor incondicional",
            subTitle: "Valoramos el amor incondicional como una fuerza motriz para el cambio positivo. Nutrimos el amor incondicional en nuestras relaciones, demostrando compasión, empatía y apoyo a los que atendemos."
          },
          {
            title: "Calidad",
            subTitle: "Buscamos la excelencia en todo lo que hacemos. Nos comprometemos con la calidad en nuestros programas, cursos y servicios, asegurando que satisfacen las necesidades y expectativas de nuestros participantes. La calidad es un reflejo de nuestro compromiso con la transformación positiva."
          },
          {
            title: "Mejora constante",
            subTitle: "Reconocemos que el aprendizaje y el crecimiento continuo son esenciales para alcanzar nuestra misión y visión. Siempre estamos buscando mejorar nuestros métodos, técnicas y enfoques. Valoramos la adaptabilidad y la innovación como medios para mantenernos relevantes y efectivos en nuestro trabajo."
          },
        ],
        bottomText: "<p><strong>Nuestra misión es simple pero poderosa: inspirar vidas, cultivar empatía y promover la paz.</strong></p>"
      },
      COMP00004: {
        title: "NUESTROS NÚMEROS",
        cards: [
          { span: "5 AÑOS DE INSTITUTO" },
          { span: "DISMINUCIÓN DE LA TASA DE VIOLENCIA DEL 65% AL 2%" },
          { span: "95% DE APROBACIÓN DE LOS" },
          { span: "MÁS DE 200 PARTICIPANTES" }
        ],
        bottomText: `<p><strong>Cada historia de éxito en nuestro instituto es una prueba viva de que la transformación es posible, sin importar los desafíos que hayas enfrentado en el pasado.</strong></p>`,
        button: "ÚNETE A NOSOTROS EN LA CONSTRUCCIÓN DE UN MUNDO MÁS COMPASIVO",
      },
      COMP00017: {
        title: "<h2>LÍNEA DE TIEMPO DEL INSTITUTO OLHAR</h2>",
        subTitle:
          "Aquí en el Instituto Olhar, valoramos a cada persona que cruza nuestro camino, ofreciendo amor, apoyo y oportunidades para el crecimiento.",
        cards: [
          {
            title: "2018",
            text: "Inicio de nuestro viaje y asociación con el equipo del Poder Judicial.",
          },
          {
            title: "2019",
            text: "Expandimos nuestro impacto al iniciar el trabajo con los reeducandos de la Ley Maria da Penha, enfocándonos en la rehabilitación y la construcción de un futuro libre de violencia.",
          },
          {
            title: "2020",
            text: "Adoptamos un enfoque innovador, lanzando las primeras clases en línea.",
          },
          {
            title: "2022",
            text: "Extendimos nuestras raíces hasta Taquaritinga, ampliando nuestro alcance geográfico y llevando nuestra misión a una nueva comunidad.",
          },
          {
            title: "2023",
            text: `<p>Establecimos una asociación con una escuela para un proyecto piloto, llevando nuestros principios de transformación al entorno educativo, moldeando futuros más prometedores para los estudiantes.</p>`,
          },
          {
            title: "2023",
            text: "Estamos formando productos y servicios para empresas que buscan promover el desarrollo socioemocional, el autoconocimiento y la cultura de paz entre sus empleados.",
          },
          {
            title: "2023",
            text: "<p>Además establecimos asociaciones con organizaciones orientadas a los adictos a las drogas, con Sertãozinho y con prisiones.</p>"
          }
        ],
      },
      COMP00023: {
        title: '<h2>METAS Y OBJETIVOS</h2>',
        line1:
          `<p>Expandir Nuestra Presencia en las Redes Sociales</p>
        <ul>
          <li>Queremos alcanzar 1.000 seguidores en Instagram para compartir nuestro mensaje de transformación con una comunidad más grande.</li>
        </ul>`,
        line2:
          `<p>Llevar Nuestros Cursos a Empresas y Escuelas</p>
        <ul>
          <li>Buscamos la prospección de asociaciones con empresas y escuelas para ofrecer nuestros programas de desarrollo socioemocional y cultura de paz a públicos más amplios.</li>
        </ul>`,
      },
      COMP_ALLYA: {
        title: '¡Venga y forme parte de nuestro ecosistema del bien!',
        description: `
          <p>Su donación es muy importante para nosotros y marca la diferencia. El proceso de donación es sencillo, seguro y fácil. O dinheiro doado é revertido para as nossas ações sociais e filantrópicas. Puede donar una vez o cada mes de forma periódica. Tanto si su donación es grande como pequeña, sepa que es una prueba de su compromiso con un mundo mejor para todos. Únase hoy a nosotros para crear un futuro en el que prevalezca el poder perdurable del amor.</p>
          <p><strong>Dona todos los meses o Instituto Olhar</strong> y participa en nuestro <strong>Clube do Bem</strong>, una asociación altruista y filantrópica entre <strong>Propago y Allya.</strong></p>
          <p>Con el Clube do Bem de Propago ahorrarás dinero, harás tu día a día más práctico y lo mejor de todo, ¡tu ahorro puede convertirse en donación!</p>
          <p>¡Disfrutarás de una <strong>serie de descuentos</strong>, ventajas y beneficios en <strong>más de 30.000 establecimientos</strong> como: Netshoes, Magazine Luiza, Cinemark, Droga Raia, EspaçoLaser y muchos más!</p>
        `,
        list: [
          {
            area: 'Belleza y bienestar -',
            discount: 'Hasta el 80% de descuento',
          },
          {
            area: 'Ocio -',
            discount: 'Hasta el 50% de descuento',
          },
          {
            area: 'Restaurantes -',
            discount: 'Hasta el 35% de descuento',
          },
          {
            area: 'Cursos y Universidades -',
            discount: 'Hasta el 80% de descuento',
          },
        ],
        bottomDescription: `<p>¿Ves cómo tu economía puede hacer bien a todo el ecosistema?</p>`,
        text: `El objetivo de <strong style="color: #F73D6A;">Allya</strong> es generar ahorro y practicidad en la vida cotidiana. Con sus ventajas, es posible un estilo de vida más económico e inteligente`,
      },
      CHEC00001: {
        title: "Su donación <strong>transformará la vida</strong> de alguien.",
        legend: "Apoyo",
      },
      FAQS00001: {
        title: "<strong>Preguntas frecuentes</strong> sobre la donación:",
        cards: [
          {
            title: "¿Instituto Olhar acepta donaciones en especie (ropa, alimentos, agua, etc.)?",
            subTitle:
              "Desafortunadamente, no podemos aceptar donaciones en especie.",
          },
          {
            title: "¿Cómo puedo estar seguro de que mi donación en línea ha sido aceptada?",
            subTitle:
              "Si ingresaste una dirección de correo electrónico válida, recibirás un mensaje de confirmación de tu donación. Si no recibes un correo electrónico en 15 minutos, contáctenos.",
          },
          {
            title: "¿Cuáles son los métodos de pago para las donaciones en el sitio web?",
            subTitle:
              "Puedes hacer tu donación, ya sea puntual o recurrente con tarjeta de crédito.",
          },
          {
            title: "¿Por qué la donación recurrente es la mejor opción?",
            subTitle:
              "La donación mensual es la mejor opción tanto para el Instituto Olhar como para nuestros seguidores. Ella nos permite tener una base de apoyo confiable y proporciona financiamiento predecible. Para nuestros seguidores, es la forma más fácil de donar, ya que la tarjeta de crédito descuenta la donación automáticamente..",
          },
          {
            title:
              "¿Es segura la página de donaciones?",
            subTitle:
              "Estamos orgullosos de cumplir con estrictos estándares de seguridad de datos para procesar tu donación en línea.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Haga una visita a nosotros!`,
        iframeTitle: "Ubicación de la oficina de Instituto Olhar"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2023 - Instituto Olhar® - Todos los derechos pueden ser compartidos con usted.",
        copyrightSpan: "Pregúntanos cómo.",
      },
      SectionCookies: {
        title: "Instituto Olhar preocupase contigo e tu privacidad.",
        subTitle:
          "Este sitio web utiliza cookies y otras tecnologias para mejorar y optimizar la experiencia del usuario, bien como para analizar la atividade de los visitantes y su frequência de utilización. Si no aceptas, utilizaremos únicamente las cookies con finalidad técnica, para que la página web funcione.",
        button: "Acepto",
      },
      Checkout: i18n_translations.es.translations,
    }
  },
};

export default i18n;