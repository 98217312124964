import { Image, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Utils
import { scrollTo } from "../../../utils/helperScrolls";

// Components
import Button from "../../Button";
import DropdownLocale from "../../DropdownLocale";

// Styles
import { Wrapper, Color } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  return (
    <Wrapper 
      titleColor="#FFFFFF" 
      backgroundImageURL="/assets/instituto-olhar/HEAD00002-back.webp"
    >
      <div>
        <div className="d-flex">
          <Color color="#D26630" />
          <Color color="#ADACAC" />
          <Color color="#736356" />
        </div>
        <div className="wrapper-button-i18n">
          <div className="d-flex justify-content-center">
            <Col xs={11} md={10} lg={10} xl={8}>
              <DropdownLocale />
            </Col>
          </div>
        </div>
        <div className="d-flex justify-content-center selo">
          <Col md={3} className="imageSelo">
            <Image 
              src="/assets/SELO.webp" 
              width={123} 
              height={139} 
              alt=''
            />
          </Col>
        </div>
        <div className="d-flex justify-content-center logo">
          <Col xs={6} className="d-flex justify-content-center">
            <Image 
              src="/assets/instituto-olhar/HEAD00002-logo.webp" 
              width={293} 
              height={288} 
              alt=''
              fluid 
            />
          </Col>
        </div>
        <div className="d-flex justify-content-center ">
          <Col
            xs={10}
            md={10}
            lg={8}
            xl={7}
            className="text-center title"
          >
            <h1>{t("HEAD00002.title")}</h1>
          </Col>
        </div>
        <div className="d-flex justify-content-center ">
          <Col xs={10} md={8} lg={7} xl={6} className="text-center sub-title" dangerouslySetInnerHTML={{ __html: t("HEAD00002.subTitle") }}></Col>
        </div>
      </div>
      <div className="d-flex justify-content-center button">
        <Button
          primaryColor="#D26630"
          secondaryColor="#FFFFFF"
          onClick={() => {
            scrollTo("COMP00001");
          }}
        >
          {t("HEAD00002.button")}
        </Button>
      </div>
    </Wrapper>
  );
};

export default Wireframe;
