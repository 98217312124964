import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
    background-color: #ADACAC;
    @media ${devices.xs} {
        padding: 2em 1em;
    }
    @media ${devices.sm} {
        padding: 2em;
    }
    @media ${devices.md} {
        padding: 6em 8em;
    }
`;

export const WrapperTitle = styled.h2`
    margin-bottom: .4em;
    color: ${({ titleColor }) => titleColor};
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
`;

export const WrapperDescription = styled.div`
    padding: 1em 0;
    font-size: 1.3em;
`;

export const RowContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 2em;
`;

export const Column = styled.div`
    margin: 0 auto;
    font-size: 1.3em;
    text-align: center;
    max-width: 700px;
`;

export const Box = styled.div`
    width: 210px;
    height: 250px;
    padding: 10px;
    border: 4px solid #7446CE;
    border-radius: 20px;
    background-color: #FFF;
`;