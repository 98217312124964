import _ from "lodash";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";


// Styles
import { Wrapper, WrapperCard, WrapperText } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  const CARDS = t("COMP00005.cards", { returnObjects: true });

  return (
    <Wrapper
      backgroundColor="#736356"
      id="COMP00005"
    >
      <div className="block-top d-flex flex-column justify-content-center align-items-center">
        <Col
          xs={10}
          sm={10}
          md={10}
          lg={6}
          className="d-flex justify-content-center"
        >
          <WrapperText titleColor="#FFF">
            <h2>{t("COMP00005.title")}</h2>
          </WrapperText>
        </Col>
      </div>
      <div className="block-bottom" id="COMP00005-cards">
        <Row className="justify-content-center g-0 h-100">
          <Col xs={12} sm={12} md={12} lg={12} xl={10}>
            <Row className="justify-content-center g-0 h-100 row-cards">
              {_.map(CARDS, (item) => (
                <Col
                  xs={10}
                  sm={10}
                  md={4}
                  lg={3}
                  className="d-flex justify-content-center"
                >
                  <WrapperCard
                    mainColor="#D26630"
                    numberColor="#FFF"
                    cardBackgroundColor="#FFF"
                    cardShadowColor="#D26630"
                    descriptionColor="#000"
                  >
                    <div className="number">{item.title}</div>
                    <div className="content">
                      <div className="content-description" dangerouslySetInnerHTML={{ __html: item.text }}></div>
                    </div>
                  </WrapperCard>
                </Col>
              ))
              }
            </Row>
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
};

export default Wireframe;