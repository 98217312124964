import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;
  
  .col-left {
    padding: 60px 0;
    @media ${devices.md} {
      padding-right: 48px;
    }
  }

  .col-right {
    background-image: url(${({ rightBackgroundImageURL }) => rightBackgroundImageURL});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export const Image = styled.img`
  margin-bottom: ${({ marginLogo }) => marginLogo};
`;

export const WrapperText = styled.div`
  ${({ titleColor, alignment }) =>
    titleColor
      ? `
    h2 {
      margin-bottom: 35px;
      color: ${titleColor};
      font-size: 28px;
      font-weight: 800;
      line-height: 32px;
      text-align: ${alignment};
    }
  ` : ""}

  ${({ descriptionColor, alignment }) =>
    descriptionColor
      ? `
      margin-bottom: 50px;
      color: ${descriptionColor};
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      text-align: ${alignment};
  ` : ""}
`;
