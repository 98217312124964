import _ from "lodash";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Styles
import { Wrapper, WrapperCard, WrapperText } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  const cards = t("COMP00017.cards", { returnObjects: true });

  return (
    <Wrapper 
      backgroundColor="#ADACAC"
      circleCardColor="#736356"
      id="COMP00017"
    >
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Col
          xs={10}
          sm={10}
          md={10}
          lg={6}
          xl={10}
          className="d-flex justify-content-center"
        >
          <WrapperText titleColor="#736356" dangerouslySetInnerHTML={{ __html: t("COMP00017.title") }}></WrapperText>
        </Col>
        <Col
          xm={12}
          sm={12}
          md={6}
          lg={10}
          xl={20}
          className="d-flex justify-content-center align-items-center circles"
        >
          {_.map(cards, (item, index) => (
            <WrapperCard 
              borderColor="#736356"
              textColor="#000"
              key={index}
            >
            <div className="circleCard">
              <h3 className="text-center">{_.get(item, "title")}</h3> 
            </div>
            <div className="d-flex">
              <div className="text text-center" dangerouslySetInnerHTML={{ __html: _.get(item, "text") }}>
              </div>
            </div>
          </WrapperCard>
          ))}
        </Col>
        <div className="d-flex flex-row justify-content-center align-items-start contentDown">
          <Col
            xs={11}
            sm={10}
            className="d-flex justify-content-center"
          >
            <WrapperText
              descriptionColor="#000"
              dangerouslySetInnerHTML={{ __html: t("COMP00017.subTitle") }}
            ></WrapperText>
          </Col>
        </div>
      </div>
    </Wrapper>
  );
};

export default Wireframe;
