import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Components
import Button from "../../Button";

// Styles
import { Wrapper, WrapperText } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  return (
    <Wrapper  
      rightBackgroundImageURL="/assets/instituto-olhar/COMP00003-back.webp" 
      id="COMP00003"
    >
      <Row className="justify-content-center g-0 h-100">
        <Col
          xm={12}
          sm={12}
          md={6}
          className="d-flex flex-column justify-content-center align-items-center align-items-md-end col-left"
        >
          <Col xs={10} sm={10} md={10} lg={11} xl={11}>
            <WrapperText
              titleColor="#D26630"
              alignment="center"
            >
              <h2>{t("COMP00003.title")}</h2>
            </WrapperText>
          </Col>
          <Col xs={10} sm={10} md={10} lg={11} xl={11}>
            <WrapperText
              descriptionColor="#000"
              alignment="center"
              dangerouslySetInnerHTML={{ __html: t("COMP00003.subTitle") }}
            ></WrapperText>
          </Col>
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={11}
            xl={11}
            className="d-flex justify-content-center"
          >
            <Button
              primaryColor="#D26630"
              secondaryColor="#FFFFFF"
              icon={false}
              buttonWidthVariable={true}
            >
              {t("COMP00003.button")}
            </Button>
          </Col>
        </Col>
        <Col
          xm={12}
          sm={12}
          md={6}
          className="d-none d-md-flex flex-column justify-content-center align-items-center align-items-md-start col-right"
        ></Col>
      </Row>
    </Wrapper>
  );
};

export default Wireframe;